.subsection-block {
	margin-bottom: 16px;
	align-items: center;
	border-radius: 8px;
	border: 1px solid #e6ebe9;
	position: relative;

	.feedback {
		margin: 0;
	}

	&__pin {
		position: absolute;
		top: 15px;
		left: 865px;
		cursor: pointer;
	}

	&__parent {
		box-sizing: border-box;
		display: flex;
		padding: 32px;
		width: 100%;
	}

	&__arrow {
		margin-right: 12px;
		height: 24px;
		cursor: pointer;
	}

	&__arrow.active {
		transform: rotate(-180deg);
		cursor: pointer;
	}

	&__heading {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 8px;
		width: 780px;
	}

	&__paragraph {
		font-size: 16px;
		line-height: 20px;
		font-family: "AptosLight";
	}

	&__content {
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}
	&__details-content {
		width: 100%;
		padding: 32px;
		background-color: #f9fbfa;
		cursor: auto;
	}
}
