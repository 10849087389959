.report-title {
  background: #464f4d;
  h1 {
    font-family: "AptosFontBold";
    color: white;
    padding: 24px 0;
    margin-left: 24px;
  }
}

.report-subtitle {
  background: #eee;
  padding: 16px 24px;
}
