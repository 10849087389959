.loader {
	background: conic-gradient(#b2ef40 90deg, #000 0); /* Pie effect with two colors */
	border-radius: 50%;
	display: inline-block;
	animation: spin 1s linear infinite;
}

/* Animation */
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
