.parent-accordion {
  display: flex;
  // width: 880px;
  margin: 32px auto;
  background-color: #464f4d;
  color: white;
  align-items: center;
  padding: 18px 24px;
  cursor: pointer;

  &__icon {
    margin-right: 16px;
  }
  &__text {
    font-size: 20px;
  }
  &__arrow {
    margin-left: auto;
    transform: rotate(90deg);
    font-size: 24px;
  }

  &__arrow.active {
    transform: rotate(-90deg);
    margin-right: 3px;
  }
}
