.button-container {
  display: flex;
  gap: 15px;
  margin: 20px;
}

.btn {
  background-color: #b3ef40;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.btn:hover {
  background-color: darken(#b3ef40, 15%);
}
