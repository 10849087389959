$table-border-color: #ddd;
$table-header-bg: #f4f4f4;
$table-row-hover-bg: #f9f9f9;

.table-container {
  padding: 20px;

  .header-controls {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 20px;

    label {
      margin-right: 5px;
    }

    select {
      width: 100px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    border: 1px solid $table-border-color;

    th,
    td {
      border: 1px solid $table-border-color;
      padding: 8px;
      text-align: left;
    }

    // th {
    //   background-color: $table-header-bg;
    // }

    tbody tr {
      &:hover {
        background-color: $table-row-hover-bg;
      }
    }
  }

  .expandable-row {
    background-color: #fefefe;

    td {
      padding: 16px;
      border: none;
    }
  }

  .expand-button {
    padding: 5px 10px;
    background-color: lightgray;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: darkgrey;
    }
  }
}
