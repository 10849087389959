.d3-container {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 0px 0 20px 0;

	svg {
		.chart-title {
			font-size: 12px;
			fill: #333;
			margin-bottom: 50px;
			font-weight: bold;
		}

		.chart-subtitle {
			font-size: 11px;
			fill: black;
			stroke: black;
			stroke-width: 0.5;
			stroke-opacity: 0.5;
		}

		.value-label {
			font-size: 11px;
			font-weight: bold;
			fill: black;
			stroke-width: 0.5;
			stroke-opacity: 0.5;
		}

		.column-label-group {
			.column-label {
				font-size: 11px;
				fill: #333;
				stroke: black;
				stroke-width: 0.5;
				stroke-opacity: 0.5;

				tspan {
					text-transform: capitalize;
				}
			}

			.column-subtitle {
				font-size: 10px;
				fill: #666;
				stroke: #666;
				stroke-width: 0.5;
				stroke-opacity: 0.5;

				tspan {
					font-style: italic;
				}
			}
		}

		text {
			font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
				"Open Sans", "Helvetica Neue", sans-serif;
		}

		.y-axis {
			.domain {
				stroke: #ccc;
				stroke-width: 1;
			}

			.tick {
				line {
					stroke: #ccc;
					stroke-dasharray: 2;
				}

				text {
					fill: #666;
					font-size: 11px;
				}
			}
		}

		.grid {
			.domain {
				display: none;
			}

			.tick line {
				stroke: #e0e0e0;
				stroke-dasharray: 2;
			}
		}

		.connector {
			stroke-width: 1;
		}

		.bar {
			&:hover {
				opacity: 0.8;
			}
		}

		.background {
			pointer-events: none;
		}

		/* Debug boxes for label groups */
		.column-label-group {
			&::after {
				content: "";
				position: absolute;
				border: 1px solid black;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
	}
}
