.exec-sum-para {
	margin: 24px 0;
	max-width: 940px;
	&__title {
		font-size: 20px;
		font-weight: 600;
		margin-bottom: 16px;

		&::after {
			content: "";
			flex-grow: 1; // Allows the line to grow
			height: 1px;
			background-color: #e6ebe9;
			margin-left: 12px; // Space between text and line
			margin-top: 14px;
			margin-right: 12px;
		}
	}

	&__sentance {
		font-size: 18px;
		line-height: 28px;
		padding: 8px 0;
		font-family: "AptosLight";

		// li {
		// 	// list-style-type: disc;
		// 	line-height: 24px;
		// 	font-size: 18px;
		// 	font-family: "AptosLight";
		// 	pointer-events: none;
		// 	// margin: -8px 0;
		// 	// margin-left: 24px;
		// 	margin-bottom: 10px;
		// }

		// li li {
		// 	margin-bottom: 0px;
		// }
	}
}
