.not-found-container {
	display: flex;
	flex-direction: column;
	position: fixed;
	left: 50%;
	top: 40%;
	width: 100vw;
	height: 100vw;

	.icon {
		width: 50px;
		height: 50px;
	}
}
