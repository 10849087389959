/* Variables */
$main-bg-color: var(--button-bg-color, #b3ef40); // Dynamic or fallback background color
$hover-bg-color: #a5dd3c;
$active-bg-color: #acfa1a;
$disabled-bg-color: #ccc;

/* Submit Button Styling */
.submit-button {
  width: 184px;
  padding: 12px;
  font-size: 16px;
  background-color: $main-bg-color;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:focus {
    background-color: $hover-bg-color;
  }

  &:active {
    background-color: $active-bg-color;
    transform: scale(0.98);
  }

  &:disabled {
    background-color: $disabled-bg-color;
    transform: scale(1);
    cursor: not-allowed;
  }
}
