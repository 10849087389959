:root {
	--primary-color: #b3ef40;
}

body {
	margin: 0;
	font-family: "AptosFont", sans-serif;
}

code {
	font-family: "AptosFont", sans-serif;
}

ul {
	padding: 0;
	margin-bottom: 24px;
}

// Leave for testing as may still be needed for testing bullet spacing

// body li {
// 	list-style-type: disc;
// 	padding-left: 0px;
// 	text-indent: -20px;
// 	// margin-top: 6px;
// }

a {
	text-decoration: none;
	background-color: transparent;
	color: rgb(104, 103, 103);
}

a:hover {
	color: black;
}

@keyframes spin-animation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

span {
	font-family: "AptosLight";
}

.span-bold-text {
	font-family: "AptosFont";
	font-weight: 700;
}

.loading-spinner {
	position: relative;
	width: 35px;
	height: 35px;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center center;
	background-image: url("./assets/icons/loading-spinner-blue.png");
	animation-name: spin-animation;
	animation-duration: 1500ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	margin: auto auto;
}
