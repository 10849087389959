/* Table Wrapper */
table.styled-table {
	border-collapse: collapse;
	width: 100%;
	font-family: Arial, sans-serif;
	margin: 20px 0;
	font-size: 14px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Table Headers */
.styled-table thead tr {
	background-color: #b3ef40;
	text-align: left;
	font-weight: bold;
}

.styled-table th,
.styled-table td {
	padding: 12px 15px;
	text-align: left;
	border: 1px solid #ddd;
}

/* Alternate Row Colors */
.styled-table tbody tr:nth-child(even) {
	background-color: #f3f3f3;
}

.styled-table tbody tr:hover {
	background-color: #f1f1f1;
}

/* Action Buttons */
.styled-table button {
	padding: 6px 12px;
	margin: 0 5px;
	border: none;
	border-radius: 4px;
	font-size: 14px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

/* Edit Button */
.edit-btn {
	background-color: #ffc107;
	color: #fff;
}

.edit-btn:hover {
	background-color: #e0a800;
}

/* Disable Button */
.disable-btn {
	background-color: #dc3545;
	color: #fff;
}

.disable-btn:hover {
	background-color: #c82333;
}

/* Enable Button */
.enable-btn {
	background-color: #b3ef40;
}

.enable-btn:hover {
	background-color: #a5dd3c;
}

/* Add Button Styles */
.add-btn {
	background-color: #b3ef40;
	font-size: 16px;
	padding: 10px 15px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-btn:hover {
	background-color: #a5dd3c;
	transform: scale(1.05);
}

.add-btn:active {
	transform: scale(1);
	background-color: #218838;
}

.back-btn {
	background-color: lightgrey;
	margin-right: 10px;
	font-size: 16px;
	padding: 10px 15px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease, transform 0.2s ease;
}
