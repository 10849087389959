.driver-block {
  margin-bottom: 16px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e6ebe9;

  &__parent {
    display: flex;
    padding: 32px;
  }

  &__arrow {
    margin-right: 12px;
    height: 24px;
  }

  &__arrow.active {
    transform: rotate(-180deg);
  }

  &__heading {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  &__paragraph {
    font-size: 16px;
    line-height: 20px;
    font-family: "AptosLight";
  }

  &__content {
    display: flex;
    flex-direction: column;
  }
  &__details-content {
    width: 100%;
    padding: 32px;
    background-color: #f9fbfa;
  }
}
