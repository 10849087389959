.summary-sentance {
	margin: 8px 0;
	font-size: 18px;
	font-family: "AptosLight";
	margin: 12px 0;
	// white-space: pre-line;
	line-height: 1.5;
}

br {
	display: block;
	content: "";
}
