.expanding-textarea-demo {
	font-size: 16px;
	font-family: "AptosFont";
	width: calc(100% - 70px);
	box-sizing: border-box;
	padding: 12px 24px 0 24px;
	border: 1px solid darken(#ffa202bd, 10%);
	border-radius: 12px;
	margin-right: 24px;
	margin-left: 0px;
	margin-top: 12px;
	padding-right: 50px;
	line-height: 16px;
	width: 100%;
}

.expanding-textarea-demo:focus {
	outline: none;
	border-color: darken(#ffa202bd, 10%);
}

.expanding-textarea-demo:disabled {
	background-color: #f0f0f0;
	cursor: auto;
	pointer-events: none;
}
