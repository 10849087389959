.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;

  &__button {
    text-align: center;
    margin-top: 20px;
    background-color: var(--button-bg-color);
  }
}

/* Basic reset for consistent styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "AptosFont";
}

/* Form group styling */
.form-group {
  position: relative;
  margin-bottom: 25px;
}

/* Input field styling */
.form-input {
  width: 100%;
  padding: 12px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

/* Styling for the labels with floating effect */
.form-label {
  position: absolute;
  top: 12px;
  left: 10px;
  color: black;
  font-size: 16px;
  pointer-events: none;
  transition: 0.2s ease all;
}

/* Move label up on focus or when input has text */
.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label {
  top: -8px;
  left: 8px;
  font-size: 14px;
  color: black;
  background-color: #fff;
  padding: 0 5px;
  border-radius: 5px;
}

/* Focus state for input field */
.form-input:focus {
  border-color: #a5dd3c;
}

/* Styling for the submit button */
.reset-password-button {
  width: 184px;
  padding: 12px;
  font-size: 16px;
  background-color: #b3ef40;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover and focus state for the button */
.reset-password-button:hover,
.reset-password-button:focus {
  background-color: #a5dd3c;
}

.reset-password-button:active {
  background-color: #acfa1a;
  transform: scale(0.98);
}

/* Styling for the forgot password link */
.forgot-password {
  display: block;
  text-align: center;
  margin-top: 15px;
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password:hover {
  text-decoration: underline;
}

.reset-password__errors {
  width: 80%;
  display: block;
  text-align: center;
  margin-top: 40px;
}

.error {
  color: red;
  text-align: center;
}