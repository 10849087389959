.bubble {
	border: 1px solid #dde1e0;
	border-radius: 20px;
	width: fit-content;
	display: flex;
	padding: 4px 8px;
	align-items: center;
	background-color: white;
	margin-left: 4px;

	&__icon {
		width: 12px;
		height: 12px;
		margin-right: 6px;
	}
	&__text {
		font-size: 12px;
		line-height: 16px;
		white-space: nowrap;
	}
}
