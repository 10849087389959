.example-question {
	color: #161616;
	font-size: 14px;
	line-height: 16px;
	background-color: white;
	border: 1px solid #e6ebe9;
	padding: 14px 16px;
	border-radius: 8px;
	width: 33%;
	cursor: pointer;
}
