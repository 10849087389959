.feedback {
	&__header {
		display: flex;
		align-items: center;
	}

	&__text {
		padding: 4px;
		padding-bottom: 0px;
		font-family: "AptosSemiBold";
	}
}
