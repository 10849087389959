.exec-sum-para-demo {
  margin: 24px auto;
  max-width: 880px;
  &__title {
    font-size: 20px;
    font-weight: 600;
    display: flex; // Ensures ::after behaves as part of flexbox
    margin-bottom: 16px;
  }

  &__sentance {
    font-size: 18px;
    line-height: 28px;
    padding: 8px 0;
    font-family: "AptosLight";

    li {
      list-style-type: disc;
      line-height: 28px;
      font-size: 18px;
      font-family: "AptosLight";
      pointer-events: none;
      margin: -8px 0;
      margin-left: 24px;
    }
  }
}
