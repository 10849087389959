.ag-theme-quartz .hidden-row {
  display: none;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-theme-quartz .header-1 {
  background-color: #ebf9fa;
  text-wrap: wrap;
  text-align: center;
  font-size: 12px;
}
.ag-theme-quartz .header-2 {
  text-wrap: wrap;
  text-align: center;
  font-size: 12px;
}
.ag-theme-quartz .top-header-1 {
  font-size: 13px;
  font-weight: 600;
}
.ag-theme-quartz .top-header-2 {
  background-color: #ebf9fa;
  font-size: 13px;
  font-weight: 600;
}
