.section-title {
	display: flex;
	justify-content: space-between;
	width: 960px;
	background-color: #c7cac9;
	font-size: 20px;
	padding: 16px 24px;
	font-family: "AptosSemiBold";
	//   border-top: 1px solid #e6ebe9;
	border-left: 1px solid #e6ebe9;
	border-right: 1px solid #e6ebe9;
	margin-bottom: 24px;

	&__pin {
		cursor: pointer;
	}
}
