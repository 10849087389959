.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &.fullscreen {
    width: 100% !important;
    height: 100% !important;
    border-radius: 0;
  }

  .modal-header {
    padding: 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin: 0;
      font-size: 1.2rem;
    }

    .modal-controls {
      display: flex;
      gap: 8px;

      button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;

        &:hover {
          background-color: #f0f0f0;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .modal-content {
    flex: 1;
    overflow: auto;
    padding: 16px;
  }

  .resize-handle {
    position: absolute;
    background: transparent;

    &.e {
      right: -5px;
      top: 0;
      width: 10px;
      height: 100%;
      cursor: e-resize;
    }

    &.s {
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 10px;
      cursor: s-resize;
    }

    &.se {
      bottom: -5px;
      right: -5px;
      width: 10px;
      height: 10px;
      cursor: se-resize;
    }
  }
} 