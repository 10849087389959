#expandable-div {
	font-size: 12px;
	color: red;
	line-height: 18px;
	padding-top: 8px;
}

#readMoreButton {
	cursor: pointer;
	color: dodgerblue;
}
