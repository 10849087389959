@font-face {
	font-family: "AptosFont";
	src: url("./aptos/Aptos.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "AptosFontItalic";
	src: url("./aptos/Aptos-Italic.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "AptosFontBold";
	src: url("./aptos/Aptos-Bold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "AptosSemiBold";
	src: url("./aptos/Aptos-SemiBold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "AptosLight";
	src: url("./aptos/Aptos-Light.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "AptosDisplay";
	src: url("./aptos/Aptos-Display.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "AptosSemiBold";
	src: url("./aptos/Aptos-SemiBold.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
