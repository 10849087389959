.report-title-demo {
  background: #464f4d;
  display: flex;
  justify-content: space-between;

  h1 {
    font-family: "AptosFontBold";
    color: white;
    padding: 24px;
    padding-left: 60px;
  }

  &__info {
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__h2 {
    color: #f24726;
    font-family: "AptosSemiBold";
  }

  &__version {
    display: flex;
    justify-content: flex-end;
    color: #d4ce47;
  }

  &__date {
    display: flex;
    justify-content: flex-end;
    color: #d4ce47;
  }
}
