.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #6a11cb, #2575fc);
  color: #ffffff;
  font-family: "Arial", sans-serif;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.emoji {
  font-size: 3rem;
  margin-top: 10px;
}
