.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup-form h2 {
  text-align: center;
}

.popup-form label {
  display: block;
  margin: 10px 0;
}

.popup-form input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.popup-form button {
  width: 100%;
  cursor: pointer;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Style for the select dropdown */
select {
  width: 100%; /* Full width for consistency */
  padding: 8px; /* Padding for better usability */
  margin-top: 5px; /* Space between label and dropdown */
  border: 1px solid #ccc; /* Border for dropdown */
  border-radius: 4px; /* Rounded corners */
  font-size: 14px; /* Adjust font size */
  background-color: #fff; /* White background */
  appearance: none; /* Remove default arrow styles for customization */
  cursor: pointer;
}

/* Add some spacing and structure for better UI */
.form-field {
  margin-bottom: 15px;
}

select:focus {
  border-color: #007bff; /* Highlight border color when focused */
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add focus shadow */
}

/* Option styles (browser handles this automatically) */
select option {
  padding: 8px;
  background-color: #fff;
}

/* Style for disabled placeholder option */
select option:disabled {
  color: #999; /* Grey color for disabled options */
}