.signin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;

  &__logo {
    background-image: url("../../assets/logo/goodwork-ai_logo_POS_Large_Green.png");
    width: 50%;
    height: 20%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__button {
    background-color: var(--button-bg-color);
    text-align: center;
    margin-top: 20px;
  }
}

/* Basic reset for consistent styling */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "AptosFont";
}

/* Container styling for centering the form */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

/* Form group styling */
.form-group {
  position: relative;
  margin-bottom: 25px;
}

/* Input field styling */
.form-input {
  width: 100%;
  padding: 12px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

/* Styling for the labels with floating effect */
.form-label {
  position: absolute;
  top: 12px;
  left: 10px;
  color: black;
  font-size: 16px;
  pointer-events: none;
  transition: 0.2s ease all;
}

/* Move label up on focus or when input has text */
.form-input:focus + .form-label,
.form-input:not(:placeholder-shown) + .form-label {
  top: -8px;
  left: 8px;
  font-size: 14px;
  color: black;
  background-color: #fff;
  padding: 0 5px;
  border-radius: 5px;
}

/* Focus state for input field */
.form-input:focus {
  border-color: #a5dd3c;
}

/* Styling for the submit button */
.login-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;

  background-color: #b3ef40;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Hover and focus state for the button */
.login-button:hover,
.login-button:focus {
  background-color: #a5dd3c;
}

.login-button:active {
  background-color: #acfa1a;
  transform: scale(0.98);
}

/* Styling for the forgot password link */
.forgot-password {
  display: block;
  text-align: center;
  margin-top: 15px;
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password:hover {
  text-decoration: underline;
}

/* Loader animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Disabled button style when loading */
.login-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

/* Error message styling */
.error-message {
  color: #d9534f; /* Bootstrap red color */
  font-size: 14px;
  margin-top: 15px;
  text-align: center;
}
