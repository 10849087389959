.view-details {
  display: flex;
  align-items: center;
  width: 960px;
  margin: 24px;
  cursor: pointer;

  &__text {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.25px;
  }

  &__arrow {
    transform: rotate(90deg);
    font-size: 24px;
    margin-right: 12px;
  }

  &__arrow.active {
    transform: rotate(-90deg);
    margin-right: 15px;
    margin-left: -3px;
  }
}
