.metric-block {
  box-sizing: border-box;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid;
  max-width: calc(50% - 4px);
  margin-bottom: 10px !important;

  &__heading {
    font-size: 18px;
    margin-bottom: 8px;
  }
  &__subheading {
    font-size: 14px;
  }
}
.metric-block.top {
  max-width: 100%;
  margin: 0 auto;
}
