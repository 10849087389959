.nav {
	position: fixed;
	width: 200px;
	height: 100vh;
	background-color: #f4f7f6;
	top: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border: 1px solid #e6ebe9;

	&__collapsed-container {
		border: 1px solid #e6ebe9;
		position: fixed;
		width: 50px;
		height: 100vh;
		background-color: #f4f7f6;
		top: 0;
		display: flex;
		align-items: center;
		overflow: hidden;
		flex-direction: column;
	}

	&__collapsed-toggle-container {
		width: 100%;
		height: 64px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-bottom: 1px solid #e6ebe9;
	}

	&__toggle-icon {
		box-sizing: border-box;
		transform: rotate(180deg);
		cursor: pointer;
		margin-left: auto;
	}

	&__toggle-icon.collapsed {
		box-sizing: border-box;
		transform: rotate(0deg);
		cursor: pointer;
		margin: 0;
	}

	&__icon {
		box-sizing: border-box;
		height: 20px;
		width: 20px;
		cursor: pointer;
		margin-right: 12px;
	}

	&__icon.collapsed {
		box-sizing: border-box;
		height: 20px;
		width: 20px;
		margin-right: 0px;
	}

	&__icon.collapsed.settings {
		margin-top: auto;
		margin-bottom: 12px;
	}
	&__icon.collapsed.user {
		border-top: 1px solid #e6ebe9;
		width: 100%;
		height: 40px;
		margin-top: 12px;
		margin-bottom: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 30px;
		padding-bottom: 24px;
	}
	&__icon.collapsed.user-icon {
		width: 32px;
		height: 32px;
	}

	&__col-icons-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 22px;
		justify-content: center;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 6px;
			background-color: white;
			margin-bottom: 12px;
			border: 1px solid #e6ebe9;
			cursor: pointer;
			border-radius: 8px;
			pointer-events: all;
		}
	}

	&__icons-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 24px 12px 0px 24px;
	}

	&__logo {
		width: 70%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		margin: 0 auto;
	}

	&__block {
		margin: 24px 0 24px 24px;
	}

	&__list {
		list-style-type: none;
		::before {
			content: "";
		}
	}

	&__item {
		margin: 24px 0;
		font-size: 16px;
		list-style-type: none;
		padding-left: 0px;

		a {
			display: flex;
			align-items: center;
		}

		&--welcome {
			border-top: 1px solid #e6ebe9;
			font-size: 16px;
			padding-top: 16px;
			margin-left: -24px;
			padding-left: 24px;
		}
	}

	&__block--user {
		margin-top: auto;
		margin-bottom: 24px;
		margin-left: 24px;
	}

	&__logout-text {
		margin-bottom: 12px;
		font-size: 12px;
		margin-left: -6px;
	}
}

li {
	list-style-type: none;
	cursor: pointer;
	pointer-events: all;
}
