.waterfall {
	.x-axis-labels {
		display: flex;
		text-align: center;
		font-family: "AptosFont", sans-serif;
		font-size: 14px;
		margin-left: 99px;
		span {
			padding: 10px;
			margin-bottom: -10px;
			z-index: 10;
			display: flex;
			flex-direction: column;
		}
	}
	.x-axis-labels.gpTop {
		margin-left: 83px;
	}
	.x-axis-labels.gpBottom {
		margin-left: 88px;
	}
	.x-axis-labels.salesTop {
		margin-left: 83px;
	}
	.x-axis-labels.salesBottom {
		margin-left: 95px;
	}

	&_multicolumn-container.salesBottom {
		margin-left: 220px;
		width: 250px;
		text-align: center;
		margin-bottom: 6px;
	}
	&_multicolumn-container.gpBottom {
		margin-left: 190px;
		width: 360px;
		text-align: center;
		margin-bottom: 6px;
	}

	&__multicolumn-title {
		font-size: 14px;
		margin-bottom: 6px;
	}
	&__multicolumn-subtitle {
		font-size: 12px;
	}
}

#chartContainer {
	height: 400px;
	width: 100%;
}

.canvasjs-chart-credit {
	display: none;
}
