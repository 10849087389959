.block-text {
}

.block-text.performance {
	background-color: white;
	box-sizing: border-box;
	margin: -24px -24px 0 -24px;
	padding: 24px;
	position: relative;

	.performance-block__pin {
		position: absolute;
		left: 920px;
		margin-top: -10px;
		cursor: pointer;
	}
}
