// Variables
$success-bg-color: #d8f7a1; // Light green background
$success-border-color: #9ecf36; // Slightly darker border
$success-text-color: #5b7c24; // Dark green text
$box-shadow-color: rgba(0, 0, 0, 0.1);

// Success Alert Styles
.success-alert {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: $success-bg-color;
  border: 1px solid $success-border-color;
  border-radius: 5px;
  color: $success-text-color;
  font-size: 16px;
  font-family: Arial, sans-serif;
  margin: 15px 0;
  box-shadow: 0 2px 4px $box-shadow-color;

  .icon {
    margin-right: 10px;
    font-size: 20px;
  }
}
