.report {
	background-color: #f6f7f7;
	min-height: 100vh;
	// padding: 0 50px;
	padding-bottom: 12px;

	&__header {
		box-sizing: border-box;
		height: 100px;
		width: 100%;
		display: flex;
		flex-direction: column;
		position: fixed !important;
		z-index: 1000;
		margin-top: -100px;
		padding-right: 50px;
	}

	&__header-bottom {
		height: 48px;
		padding: 0 65px;
		border-top: 1px solid #e6ebe9;
		border-bottom: 1px solid #e6ebe9;
		display: flex;
		align-items: center;
		background-color: #f6f7f7;
	}

	&__summary {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 12px;
		margin: 0 3px;
		white-space: nowrap;
	}

	&__logo {
		background-image: url("../../../assets/logo/goodwork-ai_logo_POS_Large_Green.png");
		height: 100%;
		width: 180px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	&__watermark {
		background-image: url("../../../assets/logo/logo.png");
		height: 30px;
		width: 30px;
		border-radius: 100%;
		background-position: center;
		background-repeat: no-repeat;
		border: 1px solid white;
	}

	&__comment-textbox {
		margin: 24px auto 24px 69px;
	}

	&__content {
		padding: 20px;
		background-color: white;
		border-radius: 12px;
		width: 1040px;
		margin: 0 auto;
		margin-top: 100px;

		.report-analysis {
			width: 960px;
			margin: 24px auto;

			&__section {
				padding: 24px;
				border: 1px solid #e6ebe9;
				margin-bottom: 32px;
			}

			&__section.drivers {
				padding-top: 0px;
			}

			&__sales-content {
				display: flex;
			}

			&__sales-metrics {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				width: 80%;
				margin-right: 24px;
			}
		}
	}

	.report__comment-textbox {
		.report__feedback-header {
			display: flex;
			align-items: center;
		}
		span {
			padding: 4px;
			font-family: "AptosSemiBold";
		}
	}

	&__content-header {
		margin: 24px 0;
	}

	&__testing {
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 1100px;
		margin-left: -24px;
		bottom: 0;
		position: fixed;
		z-index: 10000;
		color: red;
		background-color: #f6f7f7;
	}
	.feedback_parent {
		margin: 0 24px;
	}
}
