.summary-sentance-bullet {
	margin: 8px 0;
	font-size: 18px;
	font-family: "AptosLight";
	line-height: 1.5;
	margin: 12px 0;
}

br {
	display: block;
	content: "";
}
