.ag-theme-quartz {
	.ag-menu {
		z-index: 100000 !important;
	}
	width: 100%;
	.negative-value-class {
		color: #b73125;
	}

	.positive-value-class {
		color: #238755;
	}

	.ag-header-cell {
		background-color: transparent;
	}

	.ag-header-cell-sortable {
		background-color: transparent;
	}
}

.ssrm-tree-data-table-container {
	width: 100%;

	.ssrm-tree-data-table-controls {
		margin-bottom: 8px;
		display: flex;
		justify-content: flex-end;

		.expand-view-button {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 6px;
			background: none;
			border: 1px solid #ddd;
			border-radius: 4px;
			cursor: pointer;
			transition: background-color 0.2s;

			&:hover {
				background-color: #f0f0f0;
			}

			svg {
				width: 18px;
				height: 18px;
			}
		}
	}
	.expanded-table-title {
		margin-bottom: 12px;
		margin-left: 12px;
	}

	.ag-grid-container {
		height: 95%;
		width: 100%;
	}
}

.modal-content {
	.ag-theme-quartz {
		width: 100%;
		height: 100%;
	}
}

.modal-table {
	height: 100%;
	width: 100%;
	overflow: auto;

	table {
		width: 100%;
		border-collapse: collapse;
	}

	th,
	td {
		padding: 8px;
		border: 1px solid #ddd;
	}

	th {
		background-color: #f5f5f5;
		position: sticky;
		top: 0;
		z-index: 1;
	}
}

.description-section {
	font-size: 12px;
	.summary-sentance {
		font-size: 14px;
	}
}
