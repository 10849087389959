$table-border-color: #ddd;
$table-header-bg: #f4f4f4;
$table-row-hover-bg: #f9f9f9;

.table-container {
	padding: 20px;

	table {
		width: 100%;
		border-collapse: collapse;
		margin: 20px 0;
		border: 1px solid $table-border-color;

		th,
		td {
			border: 1px solid $table-border-color;
			padding: 8px;
			text-align: left;
		}

		// th {
		//   background-color: $table-header-bg;
		// }

		tbody tr {
			&:hover {
				background-color: $table-row-hover-bg;
			}
		}

		.action-td {
			width: 238px;
		}
	}

	.expandable-row {
		background-color: #fefefe;

		td {
			padding: 16px;
			border: none;
		}
	}

	.expand-button {
		padding: 5px 10px;
		background-color: lightgray;
		border: none;
		border-radius: 4px;
		cursor: pointer;

		&:hover {
			background-color: darkgrey;
		}
	}
}

.upload-button {
	background-color: #007bff;
	color: white;
	padding: 6px 12px;
	border-radius: 5px;
	margin-right: 3px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
}

.upload-button:hover {
	background-color: #0056b3;
}

.btn-success {
	background-color: #28a745; /* Green color */
	color: white;
	padding: 6px 12px;
	margin: 0px 3px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	font-size: 16px;
	transition: background-color 0.3s ease-in-out;
}

.btn-success:hover {
	background-color: #218838; /* Darker green on hover */
}

.btn-danger {
	background-color: #dc3545; /* Red color */
	color: white;
	margin: 0px 3px;
	padding: 6px 12px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	font-size: 16px;
	transition: background-color 0.3s ease-in-out;
}

.btn-danger:hover {
	background-color: #c82333; /* Darker red on hover */
}

.btn-retry {
	background-color: #fbea00;
	margin: 0px 3px;
	padding: 6px 12px;
	border: none;
	cursor: pointer;
	border-radius: 5px;
	font-size: 22px !important;
	transition: background-color 0.3s ease-in-out;
}

.btn-retry:hover {
	background-color: darken(#fbea00, 10%);
}
