.split-layout-container {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  
  &.horizontal {
    flex-direction: column;
  }
  
  &.vertical {
    flex-direction: row;
  }
}

.split-layout-first,
.split-layout-second {
  overflow: auto;
  position: relative;
}

.split-layout-divider {
  position: relative;
  background-color: #e0e0e0;
  
  &.horizontal {
    height: 6px;
    cursor: row-resize;
  }
  
  &.vertical {
    width: 6px;
    cursor: col-resize;
  }
  
  &:hover, &:active {
    background-color: #bdbdbd;
  }
  
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #757575;
  }
  
  &.horizontal::after {
    width: 30px;
    height: 2px;
  }
  
  &.vertical::after {
    width: 2px;
    height: 30px;
  }
}

/* First-time indicator that appears briefly when component mounts */
.split-layout-container.new-user .split-layout-divider::before {
  content: 'Drag to resize';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  pointer-events: none;
  z-index: 10;
}

.split-layout-container.horizontal.new-user .split-layout-divider::before {
  left: 50%;
  top: -20px;
  transform: translateX(-50%);
}

.split-layout-container.vertical.new-user .split-layout-divider::before {
  left: -20px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: right center;
}

.divider-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 5;
}

.divider-dots {
  color: #757575;
  font-size: 14px;
  letter-spacing: 2px;
  display: inline-block;
  
  &.vertical {
    transform: rotate(0deg);
  }
  
  &:not(.vertical) {
    transform: rotate(90deg);
  }
}

.split-layout-divider:hover .divider-dots {
  color: #2196f3;
} 