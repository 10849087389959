.button {
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: 500;
	color: black;
	background-color: var(--button-bg-color);
	border-radius: 5px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	transition: background-color 0.3s ease, transform 0.2s ease;
	border: 1px solid #e6ebe9;
}

.button:hover {
	background-color: #a5dd3c;
}

.button:active {
	background-color: #acfa1a;
	transform: scale(0.98);
}
