.chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  border-right: 1px solid #e6ebe9;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
  }

  &__button {
    cursor: pointer;
    margin: 0px 24px 0px 24px;
    border: 1px solid #e6ebe9;
    padding: 4px 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 32px;
  }

  &__button-icon {
    margin-right: 8px;
  }

  &__logo {
    margin-top: 0px;
    width: 140px;
    height: 28px;
    margin-right: 24px;
  }

  &__window {
    width: 80%;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 12px;
    margin: 24px auto;
    margin-bottom: 0px;
  }

  &__window.centered {
    display: none;
  }

  &__user-message {
    font-size: 16px;
    width: fit-content;
    margin-left: auto;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 12px;
    padding-left: 24px;
    max-width: 60%;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  &__user-icon {
    margin-left: 16px;
    width: 32px;
    height: 32px;
    background-color: rgb(233, 233, 233);
    border-radius: 24px;
  }

  &__response-message {
    font-size: 16px;
    padding: 16px 16px 16px 24px;
    margin: 24px 0;
    display: flex;
    background-color: #f9faf9;
    border: 1px solid #e6ebe9;
    border-radius: 16px;
    line-height: 24px;
  }

  &__response-logo {
    background-image: url("../../../assets/logo/u3.svg");
    margin-right: 24px;
    height: 24px;
    width: 24px;
    background-repeat: none;
    background-size: cover;
    flex-shrink: 0;
  }

  &__response-text {
  }

  // &__response-text {
  // 	animation: left-to-right-fade-in 1s ease-in;
  // 	-webkit-mask-repeat: no-repeat;
  // }
  // @keyframes left-to-right-fade-in {
  // 	0% {
  // 		-webkit-mask-size: 0%;
  // 		-webkit-mask-image: linear-gradient(
  // 			to right,
  // 			rgba(0, 0, 0, 1) 70%,
  // 			rgba(0, 0, 0, 0)
  // 		);
  // 	}
  // 	100% {
  // 		-webkit-mask-size: 100%;
  // 		-webkit-mask-image: linear-gradient(
  // 			to right,
  // 			rgba(0, 0, 0, 1) 70%,
  // 			rgba(0, 0, 0, 0)
  // 		);
  // 	}
  // }

  &__textarea {
    display: flex;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    flex: 0 0 auto;
    padding: 16px 0;
  }

  &__textarea.centered {
    margin: auto auto;
    display: block;
  }

  &__heading {
    // width: 100%;
    width: fit-content;
    margin-left: 30px;
    font-size: 40px;
    font-family: "AptosSemiBold";
    margin-bottom: 32px;
    background: linear-gradient(to right, #ffffff, #dff2e2);
  }

  &__submit {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-left: -60px;
  }
  &__submit.centered {
    height: 25px;
    width: 25px;
    cursor: pointer;
    margin-left: -70px;
    margin-bottom: 12px;
  }

  &__submit:hover {
    color: #a5dd3c;
    cursor: pointer;
  }

  &__suggest-cont {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 0 auto;
    width: calc(100% - 36px);
    background-color: #f4f7f6;
    padding: 18px 16px 14px 16px;
    border-radius: 12px;
    min-height: 240px;
    margin: -118px auto 0 auto;
  }

  @media (max-width: 1400px) {
    &__suggest-cont {
      height: 250px;
    }
  }
  @media (max-width: 1260px) {
    &__suggest-cont {
      height: 260px;
    }
  }

  &__sugg-cont-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__examples {
    font-size: 14px;
    color: #4d5150;
  }

  &__idea {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #4d5150;
    cursor: pointer;
  }

  &__refresh-icon {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  &__examples-container {
    display: flex;
    margin-top: 14px;
    justify-content: space-between;
  }

  &__testing {
    width: 100%;
    position: absolute;
    text-align: center;
    z-index: -5;
    color: red;
    font-weight: bold;
  }
}
