.bullet-point {
  list-style-type: disc;
  font-size: 18px;
  font-family: "AptosLight";
  pointer-events: none;
  margin: 12px 0;
  margin-left: 24px;
  line-height: 28px;
  padding-left: 0.5em;
  text-indent: -1em;
}

.bullet-point::marker {
  font-size: 12px;
}
