.waterfall {
	.x-axis-labels-demo {
		display: flex;
		// justify-content: space-around;
		text-align: center;
		font-family: "AptosFont", sans-serif;
		font-size: 14px;
		// margin-bottom: -20px;
		// margin-top: 10px;
		margin-left: 91px;
		margin-right: 10px;

		span {
			padding: 10px;
			margin-bottom: -10px;
			z-index: 10;
		}
	}
	.x-axis-labels-demo.gpTop {
		margin-left: 83px;
	}
	.x-axis-labels-demo.gpBottom {
		margin-left: 85px;
	}
}

#chartContainer {
	height: 400px;
	width: 100%;
}

.canvasjs-chart-credit {
	display: none;
}
