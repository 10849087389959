.sources {
	margin: 24px 0;
	max-width: 880px;

	&__text {
		width: fit-content;
		font-size: 14px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: #1b1a1a; /* Dark text color */
		background-color: #f5f5f5; /* Light background color */
		border: 1px solid #e0e0e0; /* Light border */
		border-radius: 24px; /* Rounded corners */
		padding: 0px 12px; /* Vertical and horizontal padding */
		cursor: pointer;
		text-decoration: none;
		margin-top: 12px;

		.icon {
			margin-left: 8px; /* Space between text and icon */
			font-size: 24px;
			margin-top: -3px;
		}
	}
}
