.analyse {
  box-sizing: border-box;
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: #f4f7f6;

  &__category {
    margin: 16px 0px;
    padding: 16px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #e6ebe9;
    font-size: 12px;
    margin-bottom: 3px;
  }

  &__category.other-filters {
    padding: 8px 16px;
    margin-top: -8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    .analyse__category-heading.other-filters {
      margin: 0;
      padding-bottom: 12px;
      cursor: pointer;
    }
  }

  &__icon {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  &__category-heading {
    font-size: 14px;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__item {
    margin-bottom: 10px;

    .css-b62m3t-container {
      font-size: 14px;
      .css-gk1ulm-control {
        border-radius: 8px;
      }
    }
  }

  &__title {
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 16px;
  }

  &__container {
    overflow-y: auto;
    // height: 30%;
    display: flex;
    padding-left: 12px;

    .analyse__breakdown {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      margin-right: 12px;
    }
  }

  &__summary {
    width: 100%;
  }

  &__summary-heading {
    font-size: 16px;
    line-height: 24px;
    margin: 18px 0;
  }
}
