.details-container {
	&__content {
		margin-top: 24px;
		margin-bottom: 24px;
	}
	&__heading {
		font-size: 18px;
		font-family: "AptosSemiBold";
		margin-bottom: 4px;
	}
	&__subheading {
		font-size: 16px;
		font-family: "AptosLight";
		color: #525252;
		margin-bottom: 32px;
	}

	&__table {
		margin-top: 24px;
	}

	&__gap.sales {
		width: 730px;
		margin-left: auto;
		margin-right: 10px;
		height: 30px;
		background-color: #f9fbfa;
		position: relative;
		top: -13px;
		margin-bottom: -13px;
		display: flex;
	}

	&__gap-1-sales {
		width: 180px;
		border-top: 30px solid #f9fbfa;
		// border-left: 50px solid transparent;
		border-right: 32px solid transparent;
		// border-bottom: 10px solid black;
		position: relative;
	}
	&__gap-2-sales {
		width: 290px;
		border-left: 32px solid transparent;
		border-right: 0px solid transparent;
		border-bottom: 30px solid #fdfef0;
		position: relative;
		margin-left: -33px;
	}
	&__gap-3-sales {
		width: 220px;
		position: relative;
		margin-left: -72px;

		height: 100%;
		background-color: #f0f9fe;
		clip-path: polygon(0% 0%, 83% 0%, 100% 100%, 33% 100%);
	}

	&__gap.gp {
		width: 735px;
		margin-left: auto;
		margin-right: 10px;
		height: 30px;
		background-color: #f9fbfa;
		position: relative;
		top: -13px;
		margin-bottom: -13px;
		display: flex;
	}

	&__gap-1-gp {
		width: 184px;
		position: relative;
		height: 100%;
		background-color: #f9fbfa;
		clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%);
	}

	&__gap-2-gp {
		width: 242px;
		position: relative;
		height: 100%;
		background-color: #fdfef0;
		clip-path: polygon(25% 0%, 100% 0%, 50% 100%, 0% 100%);
		margin-left: -60px;
	}
	&__gap-3-gp {
		width: 375px;
		position: relative;
		margin-left: -123px;
		height: 100%;
		background-color: #f0f9fe;
		clip-path: polygon(32% 0%, 82% 0%, 98% 100%, 0% 100%);
	}
}
