.expanding-textarea {
  font-size: 16px;
  font-family: "AptosFont";
  width: calc(100% - 48px);
  box-sizing: border-box;
  padding: 12px 24px 0 24px;
  border: 1px solid #dbdbdbbd;
  border-radius: 12px;
  box-shadow: 3px 3px 12px rgba(145, 145, 145, 0.5);
}

.expanding-textarea:focus {
  outline: none;
  border-color: #b3ef40;
}

.expanding-textarea:disabled {
  background-color: #f0f0f0;
  cursor: auto;
  pointer-events: none;
}
