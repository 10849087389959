.routes {
	transition: margin-left 0.5s ease, width 0.5s ease; /* Smooth transition */
	position: relative;
}

.routes.public-route {
	margin-left: 0;
	width: 100%;
}

.routes.collapsed-nav {
	margin-left: 50px;
	width: calc(100% - 50px);
}
.routes.expanded-nav {
	margin-left: 200px;
	width: calc(100% - 200px);
}
