.sales-gp {
  width: 880px;
  margin: 0px auto;

  &__heading {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  &__content {
    font-size: 18px;
    margin-bottom: 24px;
  }

  li {
    list-style-type: disc;
    font-family: "AptosLight";
    pointer-events: none;
    line-height: 24px;
    margin-bottom: 16px;
    padding-left: 1.5em; /* Adjust to match bullet size */
    text-indent: -1.5em;
  }
}
